import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/user/user.service';

@Component({
  selector: 'app-seller-edit',
  templateUrl: './seller-edit.component.html',
  styleUrls: ['./seller-edit.component.scss']
})
export class SellerEditComponent implements OnInit {

	sellerData : any = {};
	params : any = {};
	selectedPhoto: File;

  constructor(private userService: UserService, private router:Router) { }

  ngOnInit() {

  	this.sellerData = JSON.parse(localStorage.getItem("userData"));
  	console.log("Username - oninit - " + this.sellerData.username);
  }

  updateUser(){

  	if(this.sellerData.newPassword != this.sellerData.newPasswordRepeat){
  		console.log("New password and repeated password don't match.");
  	}
  	else{

  		console.log("Username - service call - " + this.sellerData.username);

	  	this.userService.updateUser(this.sellerData, this.selectedPhoto).subscribe(data => {
	      console.log("Response from data upload: " + data);
	      localStorage.setItem("userData", JSON.stringify(data['body']));
	      localStorage.setItem("targetSeller", JSON.stringify(data['body']));
	    });
    }
  }

	onFileChanged(event) {
		this.selectedPhoto = event.target.files[0];
		console.log(this.selectedPhoto);
	}

}
