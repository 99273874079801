import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from '../shared/basket/basket.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from '../utils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	userData : any = {};
	isUserLoggedIn : boolean;
	currentLanguage : string;

	constructor(private translate: TranslateService, private router: Router, public basketService: BasketService) {

	}


	public addEvent(obj, evt, fn) {
		if (obj.addEventListener) {
			obj.addEventListener(evt, fn, false);
		}
		else if (obj.attachEvent) {
			obj.attachEvent("on" + evt, fn);
		}
	}

	
 	ngOnInit() {
		this.initLanguage();
		
		this.checkIfUserLoggedIn();
		document.body.addEventListener("mouseleave", this.mouseLeaveCallback);

		window.onclick = function(event) {
			var popup = document.getElementById("savetovalistePopup");
			if (event.target == popup) {
				popup.style.display = "none";
			}
		}
	}

	public isPaymentEnabled() : boolean{
		return Utils.isPaymentEnabled;
	}

	public initLanguage(){
		this.currentLanguage = localStorage.getItem("language");
		if(this.currentLanguage == null || this.currentLanguage == undefined || this.currentLanguage == ""){
			this.setLanguage("sr");
		}
		else{
			this.setLanguage(this.currentLanguage);
		}
		console.log("Init language: " + this.currentLanguage);
	}

	public setLanguage(lang : string){
		console.log("Set language: " + lang);
		this.translate.use(lang);
		this.currentLanguage = lang;
		localStorage.setItem("language", lang);
	}

	public isBasketEmpty() : boolean{
		this.basketService.calculateWeightAndNumbers();
		return this.basketService.isBasketEmpty();
	}

	public mouseLeaveCallback(event) : void{
		
		if(localStorage.getItem("adviceShown") == null)
		{
			var popupDiv = document.getElementById("savetovalistePopup");
			popupDiv.style.display = "block";
			localStorage.setItem("adviceShown", "true");
		}
	}

	public popupOkClicked() : void{
		var popupDiv = document.getElementById("savetovalistePopup");
		popupDiv.style.display = "none";
	}
	
	checkIfUserLoggedIn(){
		this.userData = JSON.parse(localStorage.getItem('userData'));
		if(this.userData != null && this.userData['id'] != null && this.userData['activated'] == true){
			this.isUserLoggedIn = true;
		}
		else{
			this.isUserLoggedIn = false;
		}
		return this.isUserLoggedIn;
	}

	articlesClicked(){

	}

	videoClicked(){

	}

	homeClicked(){

	}

	askClicked(){

	}

	productsClicked(){
		
	}

  profileClicked(){
  	
  }

  logout(){
	console.log("Logging out");
	localStorage.setItem('userData', null);
	this.isUserLoggedIn = false;
  }
}
