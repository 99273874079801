import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ItemService {

	public API = 'https://immuno-pharma-web-service.herokuapp.com';
  	public ITEM_API = this.API + '/items';
  	public ADD_ITEM_API = this.ITEM_API + '/addItem'

  	public params :any = {};
  	public userData : any = {};

	constructor(private http: HttpClient) {

	}

	getFirstPage(): Observable<any> {
		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/items/getFirstPage');
	}

	getNextPage(nextPage : number): Observable<any> {
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["currentPage"] = nextPage;

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/items/getNextPage', {headers:headers, params: this.params});
	}

	getNew(nextPage : number): Observable<any> {
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["currentPage"] = nextPage;

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/items/getNew', {headers:headers, params: this.params});
	}

	getItemsBySeller(nextPage : number, sellerId : string): Observable<any> {
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["currentPage"] = nextPage;
      	this.params["sellerId"] = sellerId; 

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/items/getItemsBySeller', {headers:headers, params: this.params});
	}

	getSellerData(sellerUid : string):Observable<any>{
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["sellerUid"] = sellerUid; 

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/sellers/id', {headers:headers, params: this.params});
	}

	addItem(photo: File, item: any): void {

		this.userData = JSON.parse(localStorage.getItem("userData"));

		let result: Observable<Object>;
		let headers = new HttpHeaders();
		headers.append('Content-Type', undefined);


		const uploadData = new FormData();
		uploadData.append('photos', photo);
		uploadData.append('itemName', item.itemName);
		uploadData.append('brandName', item.brandName);
		uploadData.append('description', item.description);
		uploadData.append('itemType', item.itemType);
		uploadData.append('price', item.price);
		uploadData.append('shopName', this.userData.username);
		uploadData.append('shopUid', this.userData.id);
		uploadData.append('materials', item.materials);
		uploadData.append('sizes', item.sizes);
		

		this.http.post(this.ADD_ITEM_API, uploadData, {
	    	reportProgress: true,
	    	observe: 'events'
		})
		.subscribe(event => {
			console.log(event); // handle event here

		});

	}

	likeItem(itemId : string, userId : string) : Observable<any>{

		console.log("Item id from service : " + itemId);
		this.params = {};

		const uploadData = new FormData();
		uploadData.append('itemId', itemId);
		uploadData.append('userId', userId);

		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["itemId"] = itemId; 
      	this.params["userId"] = userId;

		return this.http.post('https://immuno-pharma-web-service.herokuapp.com/items/like', uploadData, {headers:headers});
	}

	unlikeItem(itemId : string, userId : string) : Observable<any>{

		this.params = {};
		
		const uploadData = new FormData();
		uploadData.append('itemId', itemId);
		uploadData.append('userId', userId);

		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["itemId"] = itemId; 
      	this.params["userId"] = userId;

		return this.http.post('https://immuno-pharma-web-service.herokuapp.com/items/unlike', uploadData, {headers:headers});
	}

	postComment(itemId : string, comment : string, userId : string, username : string): Observable<any> {
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');
      	this.userData = JSON.parse(localStorage.getItem("userData"));


      	const uploadData = new FormData();
		uploadData.append('itemId', itemId);
		uploadData.append('comment', comment);
		uploadData.append('userId', this.userData.id);
		uploadData.append('username', this.userData.username);


		return this.http.post('https://immuno-pharma-web-service.herokuapp.com/items/comment', uploadData, {headers:headers});
	}

	getItemsByFollowing(nextPage : number) : Observable<any>{

		this.userData = JSON.parse(localStorage.getItem('userData'));

		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["currentPage"] = nextPage;
      	this.params["sellerIds"] = this.userData.sellersFollowing; 

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/items/getItemsByFollowing', {headers:headers, params: this.params});
	}
  
}