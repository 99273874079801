import { Component, OnInit } from '@angular/core';
import { ItemService } from '../shared/item/item.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-single-item',
  templateUrl: './single-item.component.html',
  styleUrls: ['./single-item.component.scss']
})
export class SingleItemComponent implements OnInit {

	item : any = {};
	targetSeller : any = {};
  	userData : any = {};

  constructor(private itemService: ItemService, private router: Router) { 

  }

  ngOnInit() {
  	this.item = JSON.parse(localStorage.getItem("singleItem"));
  	if(localStorage.getItem("targetSeller") != null && localStorage.getItem("targetSeller")!=undefined){
  		this.targetSeller = JSON.parse(localStorage['targetSeller']);
  	}
  	this.userData = JSON.parse(localStorage['userData']);
  }

  sellerProfileClicked(shopUid : string){
    console.log(shopUid);

    this.itemService.getSellerData(shopUid).subscribe(data => {
      console.log(data);
      localStorage.setItem("targetSeller", JSON.stringify(data));
      this.router.navigate(['/seller-profile']);
    });
    
  }

  checkIfLiked(item:any):string{
    console.log("Checking if liked for " + item);
    let likedBy = item["likedBy"];

    if(likedBy){

      for (let id of likedBy) {
        if(id == this.userData["id"]){
          console.log("image liked");
          return "assets/icons/heartFull.png";
        }
      }
      return "assets/icons/heartEmpty.png";
    }
    else{
      return "assets/icons/heartEmpty.png";
    }
  }

  likeItem(item : any) : void{

    let liked = false;

    console.log("Item id : " + item["id"]);

    let likedBy = item["likedBy"];

    if(likedBy){

      for (let id of likedBy) {
        if(id == this.userData["id"]){
          liked = true;
          console.log("image liked");          
        }
      }

      if(liked){
        this.itemService.unlikeItem(item["id"], this.userData["id"]).subscribe(data => {
            console.log("Item already liked, unliking: " + data);
          });
      }else{
        this.itemService.likeItem(item["id"], this.userData["id"]).subscribe(data => {
          console.log("Item not liked, liking: " + data);
        });
      }
    }
    else{
      console.log("Error: can't get item likes");
    }

    
  }

  countComments(item:any):number{
    let count = 0;

    let comments = item["comments"];
    if(comments){
      for(let comment of comments){
        count++;
      }
      return count;
    }

    return 0;
  }

  postComment(comment : string){

  	console.log("Comment is : " + comment);

  	this.itemService.postComment(this.item.id, comment, this.userData.id, this.userData.username).subscribe(data => {
	    console.log("Comment post - service response: " + data);
	  });
  }

  getCommentUsername(comment : string) : string{
  	return comment.substr(0, comment.indexOf(':')); 
  }

  getCommentText(comment : string) : string{
  	return comment.substr(comment.indexOf(':')+1, comment.length);
  }

}
