import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {NavbarComponent} from '../navbar/navbar.component';
import {ItemListComponent} from '../item-list/item-list.component';
import {SellerProfileComponent} from '../seller-profile/seller-profile.component';
import { HttpClient } from  "@angular/common/http";
import {Router} from '@angular/router';
import {PopupComponent} from '../popup/popup.component'
import {LoadingPopupComponent} from '../loading-popup/loading-popup.component'
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {SecurityContext} from '@angular/core'
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import Utils from '../utils'

import * as $ from 'jquery';
import { BasketService } from '../shared/basket/basket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public addedToCartInput: Object;


	public userData : any = {};
  public currentDialog : MatDialogRef<LoadingPopupComponent, any>;

  constructor(private translate: TranslateService, private httpClient : HttpClient, private router : Router, private dialog: MatDialog, private sanitizer: DomSanitizer, 
    private basketService : BasketService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  public isSerbianVersion() : boolean{
    return Utils.isSerbianVersion;
  }

  public isPaymentEnabled() : boolean{
    return Utils.isPaymentEnabled;
  }

  public goto(pageName : string) : void {
  	console.log("Going to " + pageName);
  }

  public addToCart(product : string, inputValue : any){
    console.log("Input value: " + inputValue.value);
    console.log("Product name: " + product);
    var quantity = this.tryParseInt(localStorage.getItem(product), null);
    var inputInt = this.tryParseInt(inputValue.value, null);

    if(inputInt === null){
      inputInt = 1;
    }
    console.log("Quantity in storage: " + quantity);
    console.log("Input int: " + inputValue.value);

    if(quantity!=null){
      console.log("Quantity not null");
      quantity += inputInt;
      localStorage.setItem(product, quantity.toString());
    }
    else{
      console.log("Quantity is null");
      localStorage.setItem(product, inputInt.toString());
    }
    
    console.log(localStorage.getItem(product));
    this.basketService.calculateWeightAndNumbers();
    this.openTimedDialog(this.translate.instant("home.product_added"), 1000, null);

    this.addedToCartInput = !this.addedToCartInput;
  }

  public decreaseAmmount(inputValue : any){
    console.log("Input value: " + inputValue.value);
    var inputInt = this.tryParseInt(inputValue.value, null);
    if(inputInt == 1){
      return;
    } 
    inputValue.value = inputInt - 1;
  }

  public increaseAmmount(inputValue : any){
    console.log("Input value: " + inputValue.value);
    var inputInt = this.tryParseInt(inputValue.value, null); 
    inputValue.value = inputInt + 1;
  }

  public tryParseInt(str,defaultValue) : number{
    var retValue = defaultValue;
    if(str !== null) {
        if(str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
  }

  public isQuestionSanitized(firstName, lastName, email, question) : boolean{

    console.log(firstName);

    firstName = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, firstName));
    lastName = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, lastName));
    email = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, email));
    question = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, question));
    
    console.log(firstName);
    if(firstName =="" || lastName == "" || email == "" || question == ""){
      this.openTimedDialog(this.translate.instant("home.no_empty_fields_error"), 2000, null);
      return false;
    }

    if(!Utils.isAllLettersAndSpaces(firstName)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("home.only_char_error"), 4000, null);
      return false;
    }
    if(!Utils.isAllLettersAndSpaces(lastName)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("home.last_name_error"), 4000, null);
      return false;
    }
    if(!Utils.isEmail(email)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("home.email_error"), 4000, null);
      return false;
    }

    return true;
  }

  

  public sendQuestionButtonClicked(firstName, lastName, email, question) : void{

    console.log("Question data: " + firstName.value + ", " + lastName.value + ", " + email.value + ", " + question.value);
    
    if(!this.isQuestionSanitized(firstName.value, lastName.value, email.value, question.value))
          return;

    this.userData = JSON.parse(localStorage.getItem('userData'));
    var token : any = {};
    token = localStorage.getItem('token');
    var newQuestion : any = {};
    
    console.log(this.userData);
    console.log(localStorage.getItem('userData'));
    

    //Da li postoji korisnik u lokalu
    if(this.userData != null){

      //da li je ulogovan
      if(this.userData['id'] != null && token != null){
        
        this.currentDialog = this.openTimedDialog(this.translate.instant("home.sending"), 6000, null);
        this.currentDialog.disableClose = true;

        newQuestion['userId'] = this.userData['id'];
        newQuestion['question'] = question.value;
        newQuestion['userEmail'] = email.value;
        newQuestion['userName'] = firstName.value;
        newQuestion['userSurname'] = lastName.value;

        this.httpClient.post("https://immuno-pharma-web-service.herokuapp.com/questions/addQuestion", newQuestion).subscribe(
          data  => {
            console.log("POST Request is successful ", data);
          },
          error  => {
          
            console.log("Error", error);
            this.currentDialog.close();
            this.currentDialog = this.openTimedDialog("There was an error: " + error["statusText"] + ". Please contact our support at nameksoft@gmail.com", 4000, null);
          }
          
          )


      }else{
        console.log("Local user data exists, but no id or token");
        var newUserData : any = {'firstName' : firstName.value, 'lastName' : lastName.value, 'email' : email.value};
        localStorage.setItem('userData', JSON.stringify(newUserData));
        localStorage.setItem('question', question.value);
        localStorage.setItem('message', this.translate.instant("home.not_logged_in"));
        this.router.navigateByUrl('/register');
      }
      //Ne postoji user data 
    }else{
      var newUserData : any = {'firstName' : firstName.value, 'lastName' : lastName.value, 'email' : email.value};
      localStorage.setItem('userData', JSON.stringify(newUserData));
      localStorage.setItem('question', question.value);

      console.log("No local user data, opening login popup");
      localStorage.setItem('message', this.translate.instant("home.not_logged_in"));
      this.router.navigateByUrl('/register');
    }
  }

  public openTimedDialog(message : string, time = 1000, afterCloseMessage : string) : MatDialogRef<LoadingPopupComponent, any> {
    console.log("Opening timed dialog");
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.currentDialog = dialogRef;
    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog closed with output:", data)
    );

    setTimeout(()=>{
      this.currentDialog.close();
      if(afterCloseMessage != null){
        this.openTimedDialog(this.translate.instant("home.question_send_error"), 10000, null);
      }
    }, time);

    return dialogRef;
  }

}