import { Component, OnInit } from '@angular/core';
import {ItemListComponent} from '../item-list/item-list.component';
import { Router } from '@angular/router';
import { UserService } from '../shared/user/user.service';


@Component({
  selector: 'app-seller-profile',
  templateUrl: './seller-profile.component.html',
  styleUrls: ['./seller-profile.component.scss']
})
export class SellerProfileComponent implements OnInit {

	seller : any = {};
  userData : any = {};

  constructor(private router : Router, private userService : UserService) { }

  ngOnInit() {
  	this.seller = JSON.parse(localStorage.getItem("targetSeller"));
    this.userData = JSON.parse(localStorage.getItem("userData"));
  	console.log(this.seller["username"]);
  }

  editProfileClicked(){
  	this.router.navigate(['/seller-edit']);
  }

  followClicked(){
    this.userService.follow(this.userData.id, this.seller.id).subscribe(data => {
      console.log(data);
    });
  }

  unfollowClicked(){
    this.userService.unfollow(this.userData.id, this.seller.id).subscribe(data => {
      console.log(data);
    });
  }

  sendMessage(){
    localStorage.setItem("userToMessage", JSON.stringify(this.seller));
    this.router.navigate(['/chat-window']);
  }

}
