import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient, private router: Router) {
  	
  }

  addQuestion(id: string, email: string, firstName: string, lastName: string, question: string): Observable<any> {
   
   const body = {
    "userId" : id,
    "question" : question,
    "userEmail" : email,
    "userName" : firstName,
    "userSurname" : lastName
   }

   const httpOptions: { headers; observe; } = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    }),
    observe: 'response'
  };

    return this.http.post("https://immuno-pharma-web-service.herokuapp.com/questions/addQuestion", body, httpOptions);
   
 }
}
