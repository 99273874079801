import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import {Router} from '@angular/router';


@Injectable({providedIn: 'root'})

export class AuthService {

  constructor(private http: HttpClient, private router: Router) {
  	
  }

  registerUser(email: string, firstName: string, lastName: string, password: string): Observable<any> {
   
   const body = {

       "firstName" : firstName,
       "lastName": lastName,
       "password" : password,
       "email" : email,
       "phoneNum" : "",
       "address" : "",
       "entranceNum" : "",
       "apartmentNum" : "",
       "topicsFollowing" : [],
       "questionsAsked" : [],
       "articlesLiked" : [],
       "profilePhotoUrl": "",
       "banned": false,
       "orders" : [],
       "activated" : false,
       "basket": {},
       "cards": [] 
   }

   const headers = new HttpHeaders(
   {
       'Content-Type': 'application/json'
   });

    return this.http.post("https://immuno-pharma-web-service.herokuapp.com/users/sign-up", body, {headers: headers});
 }

 updateUser(userData : any): Observable<any> {
   
    const headers = new HttpHeaders(
    {
        'Content-Type': 'application/json'
    });
 
     return this.http.post("https://immuno-pharma-web-service.herokuapp.com/users/update", userData, {headers: headers});
  }

  registerUserFull(email: string, firstName: string, lastName: string, phoneNum: string, password: string,
     address: string, orderedItems: [], orderedQuantities: []): void {
  	
  	const body = {

        "firstName" : firstName,
        "lastName": lastName,
        "password" : password,
        "email" : email,
        "phoneNum" : phoneNum,
        "address" : address,
        "articlesLiked" : [],
        "topicsFollowing" : [],
        "questionsAsked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : [],
        "activated" : false,
        "orderedItems": orderedItems,
        "orderedQuantities": orderedQuantities 
    }

    const headers = new HttpHeaders(
    {
        'Content-Type': 'application/json'
    });

  	this.http.post("https://immuno-pharma-web-service.herokuapp.com/users/sign-up", body, {headers: headers})
		.subscribe(data => {
			console.log(data);

		});
  }


  login(credential: string, password: string) :Observable<any> {
  	console.log(credential + " : " + password);
  	const body = {
        "firstName" : "",
        "lastName": "",
        "phoneNum" : "",
        "address" : "",
        "entranceNum" : "",
        "apartmentNum" : "",
        "articlesLiked" : [],
        "topicsFollowing" : [],
        "questionsAsked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : [],
        "activated" : false
    }
    body["email"] = credential;
    body["password"] = password;
        

    const headers = new HttpHeaders(
    {
        'Content-Type': 'application/json'
    });

  	return this.http.post("https://immuno-pharma-web-service.herokuapp.com/login", body, {headers: headers, observe: "response"});
  

  }



}
