import { Component, OnInit } from '@angular/core';
import { ItemService } from '../shared/item/item.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})

export class ItemListComponent implements OnInit {
  items: Array<any>;
  public currentPage : number;
  targetSeller : any = {};
  userData : any = {};

  constructor(private itemService: ItemService, private router: Router) { 

  }



  ngOnInit() {

    this.currentPage = 0;
    console.log("Target seller from local storage: " + localStorage.getItem('targetSeller'));

    console.log("Target seller from local storage 2: " + localStorage.getItem('targetSeller'));
    
    if(localStorage.getItem('targetSeller')!=undefined && localStorage.getItem('targetSeller')!=null){
      console.log("AAAAAAAAAAAAAAAAAAA");
      this.targetSeller = JSON.parse(localStorage['targetSeller']);
    }

    console.log("Target seller: " + this.targetSeller);
    console.log("Target seller map length: " + this.getMapLength(this.targetSeller));
    this.userData = JSON.parse(localStorage['userData']);

    if(this.getMapLength(this.targetSeller) != 0){
      this.currentPage = 0;
      this.itemService.getItemsBySeller(this.currentPage, this.targetSeller["id"]).subscribe(data => {
        this.items = data;
        console.log(this.items);
      });
    }
    else{
      this.itemService.getItemsByFollowing(this.currentPage).subscribe(data => {
        this.items = data;
      });
    }
  }

  getMapLength(obj : any) : number{
      if(obj == null || obj == undefined)
        return 0;
      return Object.keys(obj).length;
  }

  public getNextPage():void{
    this.currentPage++;
    console.log("Getting page " + this.currentPage);
    this.itemService.getNextPage(this.currentPage).subscribe(data => {
      this.items = data;
      console.log(this.items);
    });
  }

  public getPreviousPage():void{
    this.currentPage--;
    console.log("Getting page " + this.currentPage);

    this.itemService.getNextPage(this.currentPage).subscribe(data => {
      this.items = data;
      console.log(this.items);
    });
  }

  public getPage(pageNumber : number):void{
    
  }

  public getNew(pageToGet : number):void{
    console.log("Getting page " + this.currentPage);
    this.itemService.getNew(pageToGet).subscribe(data => {
      this.items = data;
      console.log(this.items);
    });
  }

  singleItemClicked(item : any){
    console.log("Single item clicked: " + item);
    localStorage.setItem("singleItem", JSON.stringify(item));

    this.router.navigate(['/single-item']);
  }

  sellerProfileClicked(shopUid : string){
    console.log(shopUid);

    this.itemService.getSellerData(shopUid).subscribe(data => {
      console.log(data);
      localStorage.setItem("targetSeller", JSON.stringify(data));
      this.router.navigate(['/seller-profile']);
    });
    
  }

  checkIfLiked(item:any):string{
    console.log("Checking if liked for " + item);
    let likedBy = item["likedBy"];

    if(likedBy){

      for (let id of likedBy) {
        if(id == this.userData["id"]){
          console.log("image liked");
          return "assets/icons/heartFull.png";
        }
      }
      return "assets/icons/heartEmpty.png";
    }
    else{
      return "assets/icons/heartEmpty.png";
    }
  }

  likeItem(item : any) : void{

    let liked = false;

    console.log("Item id : " + item["id"]);

    let likedBy = item["likedBy"];

    if(likedBy){

      for (let id of likedBy) {
        if(id == this.userData["id"]){
          liked = true;
          console.log("image liked");          
        }
      }

      if(liked){
        this.itemService.unlikeItem(item["id"], this.userData["id"]).subscribe(data => {
            console.log("Item already liked, unliking: " + data);
            item = data.body;
          });
      }else{
        this.itemService.likeItem(item["id"], this.userData["id"]).subscribe(data => {
          console.log("Item not liked, liking: " + data);
          item = data.body;
        });
      }
    }
    else{
      console.log("Error: can't get item likes");
    }

    
  }

  countComments(item:any):number{
    let count = 0;

    let comments = item["comments"];
    if(comments){
      for(let comment of comments){
        count++;
      }
      return count;
    }

    return 0;
  }

}
