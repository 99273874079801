import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'client';
  constructor(private translate: TranslateService, private router: Router) {
      
    translate.setDefaultLang('sr');

      router.events.subscribe((y: NavigationEnd) => {
        if(y instanceof NavigationEnd){
          gtag('config','G-Z31T9Z0N49',{'page_path' : y.url});
          fbq('track', 'PageView');
        }
      })
  }
}
