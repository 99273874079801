import { AuthService } from '../shared/auth/auth.service';
import { QuestionService } from '../shared/question/question.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {PopupComponent} from '../popup/popup.component'
import {LoadingPopupComponent} from '../loading-popup/loading-popup.component'
import Utils from '../utils'
import {SecurityContext} from '@angular/core'
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
		private router: Router, private authService: AuthService, private dialog: MatDialog,
		private questionService : QuestionService, private sanitizer: DomSanitizer) { }

	public user: any = {};
	public question : string;
	public loadingDialog : MatDialogRef<LoadingPopupComponent, any>;
	public currentlyOpenDialog : MatDialogRef<PopupComponent, any>;
	public hasMessage : boolean = false;
	public message : string;

	ngOnInit() {

		if(localStorage.getItem('message') != null){
			this.hasMessage = true;
			this.message = localStorage.getItem('message');
		}

		if(localStorage.getItem('question') != null)
			this.question = localStorage.getItem('question');
	}

	public isLoginSanitized(email, password) : boolean{

		email = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, email));
		password = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, password));
		
		if(email =="" || password == "" ){
		  this.openDialog("No empty fields allowed");
		  return false;
		}

		if(!Utils.isEmail(email)){
		  //TODO: alert
		  this.openDialog("Email should contain characters and numbers, folowed by '@', and a domain name (.com, .org...)");
		  return false;
		}
	
		return true;
	  }
	

  	loginUser(email, password) {
		console.log("Login user");

		if(!this.isLoginSanitized(email.value, password.value))
			return;

		this.openLoadingPopup(true, 6000);
		this.authService.login(email.value, password.value).subscribe(data => {
			if(data != null){
				console.log(data['status']);
				console.log(data.body["body"]);
				console.log(data.body["userData"]);

				localStorage.setItem('token', data.body["body"]);
				localStorage.setItem('userData', JSON.stringify(data.body["userData"]));
				var user = data.body["userData"];
						
				console.log("User question: " + this.question);

				if(this.question != null && this.question != ""){
					
					//TODO: izbaciti popup koji kaze da se postavlja pitanje i ceka registracija
					this.questionService.addQuestion(user['id'], user['email'], user['firstName'], user['lastName'], this.question)
					.subscribe(data => {
						console.log(data);
						//Brisemo pitanje iz lokala
						if(localStorage.getItem('question') != null)
							localStorage.removeItem('question');
							
						if(data != null && data['status'] == 200){
							console.log("Question successfuly asked.");
							//TODO: prikazati popup koji obavestava da je sve ok i na ok vraca na Home
							if(this.loadingDialog != null)
								this.loadingDialog.close();
							this.openDialogAndGoHome();
						}else{
							//TODO: Doslo je do greske
							this.openDialog("We are sorry, there was an error on our side." + 
							" Please send us an email describing what happened to nameksoft@gmail.com, " + 
							"and you will get a free product as an appologie. Thank you.");
						}
					});
				}else{
					console.log("Loading dialog: " + this.loadingDialog);
					if(this.loadingDialog != null){
						console.log("Closing loading popup");
						this.loadingDialog.close();
					}
				}
						
				if(user["activated"]){
					this.router.navigateByUrl('/home');
				}
				else{
					this.openDialog("Svoj profil aktivirajte tako, da kliknete povezavo,"+ 
					" ki smo jo poslali na vaš e-poštni naslov.");
				}
			}else{
				console.log("Null value returned on login");
			}

		}, error => {
			if(this.loadingDialog != null){
				console.log("Closing loading popup");
				this.loadingDialog.close();
			}
			console.log("Error loggin in: " + error.status);
			if(error.status == "403"){
				this.openDialog("Uporabnika s tem e-poštnim imenom in geslom nismo mogli najti. " + 
				"Mogoče je geslo napačno ali pa ste se registrirali z drugim e-poštnim sporočilom.");
			}
		});
	}

	public openDialog(message : string) : void {

        const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;

        dialogConfig.data = {
	        id: 'message',
	        title: message
	    };

        const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
		this.currentlyOpenDialog = dialogRef;
		
        dialogRef.afterClosed().subscribe(
			data => {
				console.log("Dialog closed with output:", data);
				if(this.loadingDialog != null)
					this.loadingDialog.close();
			}
	    );
	}

	public openDialogAndGoHome(message? : string) : void{
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.disableClose = true;

        dialogConfig.data = {
	        id: 'message',
	        title: 'You are now logged in, and your question has been successfuly sent.'
	    	};

        const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
		this.currentlyOpenDialog = dialogRef;
        dialogRef.afterClosed().subscribe(
			data => {
				console.log("Dialog closed with output:", data);
				this.router.navigate(['/home']);
			}
	    );
	}

	public openLoadingPopup(afterCloseMessage, time) : void {

        const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.disableClose = true;

        dialogConfig.data = {
	        id: 'message',
	        title: 'Logging in. Please wait, this will not take long.'
	    	};

        const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
		this.loadingDialog = dialogRef;

        dialogRef.afterClosed().subscribe(
	        data => console.log("Dialog closed with output:", data)
		);

		dialogRef.afterClosed().subscribe(
	        data => this.loadingDialog = null
		);
		
		setTimeout(()=>{			
			if(afterCloseMessage != null && this.loadingDialog != null){
			  this.openTimedDialog("There was an error logging in. Please contact our support at nameksoft@gmail.com, thank you.", 6000, null);
			}
		  }, time);
	}

	public openTimedDialog(message : string, time = 1000, afterCloseMessage : string) : MatDialogRef<PopupComponent, any> {

		const dialogConfig = new MatDialogConfig();
	
		dialogConfig.autoFocus = true;
	
		dialogConfig.data = {
		  id: 'message',
		  title: message
		};
	
		const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
		this.currentlyOpenDialog = dialogRef;
		dialogRef.afterClosed().subscribe(
		  data => console.log("Dialog closed with output:", data)
		);
	
		setTimeout(()=>{
		  this.currentlyOpenDialog.close();
		  if(afterCloseMessage != null){
			this.openTimedDialog("There was an error processing your question. Please contact our support at nameksoft@gmail.com, thank you.", 6000, null);
		  }
		}, time);
	
		return dialogRef;
	  }
	


}
