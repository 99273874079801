import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from "@angular/common";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule, MatCardModule, MatInputModule, MatListModule, MatToolbarModule, MatGridListModule} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ItemListComponent } from './item-list/item-list.component';
import { FormsModule } from '@angular/forms';
import { ItemAddComponent } from './item-add/item-add.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import { MatDialogModule } from '@angular/material';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { PopupComponent } from './popup/popup.component';
import { ProfileComponent } from './profile/profile.component';
import { SellerProfileComponent } from './seller-profile/seller-profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SingleItemComponent } from './single-item/single-item.component';
import { SellerEditComponent } from './seller-edit/seller-edit.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { ClanakHolesterol1Component } from './clanak-holesterol1/clanak-holesterol1.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleShareComponent } from './article-share/article-share.component';
import { ArticleShareBottomComponent } from './article-share-bottom/article-share-bottom.component';
import { FooterComponent } from './footer/footer.component';
import { FiveWaysToSaveYourHearthComponent } from './five-ways-to-save-your-hearth/five-ways-to-save-your-hearth.component';
import { ArticleGanodermaLucidumComponent } from './article-ganoderma-lucidum/article-ganoderma-lucidum.component';
import { BasketComponent } from './basket/basket.component';
import { BasketItemComponent } from './basket-item/basket-item.component';
import { ImmunoG500Component } from './immuno-g500/immuno-g500.component';
import { ImmunoG100Component } from './immuno-g100/immuno-g100.component';
import { NaturalQ10Component } from './natural-q10/natural-q10.component';
import { AcidExComponent } from './acid-ex/acid-ex.component';
import { HolesterolExComplexComponent } from './holesterol-ex-complex/holesterol-ex-complex.component';
import { LoadingPopupComponent } from './loading-popup/loading-popup.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { EditInfoPopupComponent } from './edit-info-popup/edit-info-popup.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
//NGX-TRANSLATE
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { PopupShopComponent } from './popup-shop/popup-shop.component';

import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { PopupShopItemComponent } from './popup-shop-item/popup-shop-item.component';

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ItemListComponent,
    ItemAddComponent,
    RegisterComponent,
    LoginComponent,
    HomeComponent,
    ErrorDialogComponent,
    PopupComponent,
    ProfileComponent,
    SellerProfileComponent,
    UserProfileComponent,
    NavbarComponent,
    SingleItemComponent,
    SellerEditComponent,
    ChatWindowComponent,
    ClanakHolesterol1Component,
    ArticlesComponent,
    ArticleShareComponent,
    ArticleShareBottomComponent,
    FooterComponent,
    FiveWaysToSaveYourHearthComponent,
    ArticleGanodermaLucidumComponent,
    BasketComponent,
    BasketItemComponent,
    ImmunoG500Component,
    ImmunoG100Component,
    NaturalQ10Component,
    AcidExComponent,
    HolesterolExComplexComponent,
    LoadingPopupComponent,
    CheckoutComponent,
    EditInfoPopupComponent,
    PopupShopComponent,
    PopupShopItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatToolbarModule,
    FormsModule,
    MatGridListModule,
    FlexLayoutModule,
    MatDialogModule,
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    ErrorDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  entryComponents: [ErrorDialogComponent, PopupComponent, LoadingPopupComponent, EditInfoPopupComponent],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation - NGX-TRANSLATE
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
