import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from '../shared/basket/basket.service';
import Utils from '../utils';

@Component({
  selector: 'app-popup-shop',
  templateUrl: './popup-shop.component.html',
  styleUrls: ['./popup-shop.component.scss']
})
export class PopupShopComponent implements OnInit {

  @Input() data;

  constructor(private translate : TranslateService, private router: Router, public basketService : BasketService) { }

  public isWaitingPurchaseResponse = false;

  immuno500 = 0;
  holex = 0;
  acidex = 0;
  naturalQ10 = 0;

  immunoG500 = "immunoG500";
  acidEx = "acidEx";
  holesterolExComplex = "holesterolExComplex";
  q10 = "q10";

  cartSubtotal : number;
  cartShipping : number;
  cartTotal : number;
  userInfoExists : boolean;
  userDataString : string;
  userData : any = {};
  paymentInfoExists : boolean;
  isShown : boolean;

  public addedToCart: Object;



  ngOnInit() {
    this.isShown = false;
    console.log("Popup shop initialised");

    this.userData = null;
    this.checkProductQuantities();
    this.cartSubtotal = 0;
    this.cartTotal = 0;
    this.cartShipping = 0;

    if(localStorage.getItem("userData") != null){
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }

  }

  checkProductQuantities(){

    if(localStorage.getItem("immuno500") != null){
      this.immuno500 = Number.parseInt(localStorage.getItem("immuno500"));
    }else{
      this.immuno500 = 0;
    }
    this.basketService.products["immunoG500"] = this.immuno500;

    if(localStorage.getItem("holex") != null){
      this.holex = Number.parseInt(localStorage.getItem("holex"));
    }else{
      this.holex = 0;
    }
    this.basketService.products["holesterolExComplex"] = this.holex;

    if(localStorage.getItem("acidex") != null){
      this.acidex = Number.parseInt(localStorage.getItem("acidex"));
    }else{
      this.acidex = 0;
    }
    this.basketService.products["acidEx"] = this.acidex;

    if(localStorage.getItem("q10") != null){
      this.naturalQ10 = Number.parseInt(localStorage.getItem("q10"));
    }else{
      this.naturalQ10 = 0;
    }
    this.basketService.products["naturalQ10"] = this.naturalQ10;

    if(this.immuno500 == 0 && this.holex == 0 && this.acidex == 0 && this.naturalQ10 == 0){
      this.isShown = false;
      console.log("Is shown = false");
    }else{
      this.isShown = true;
      console.log("Is shown = true");
    }

  }

  addToTotalPrice(event : any){
    console.log("addToSubtotal");
    console.log(typeof(event));
    console.log(event);
    var price = Number((event).toFixed(2));
    this.cartSubtotal = this.basketService.calculateSubtotalPrice();
    this.basketService.priceSubtotal = this.cartSubtotal;
    console.log("Cart subtotal: " + this.cartSubtotal);

    this.cartShipping = Number(this.basketService.calculateShipping().toFixed(2));
    this.basketService.shippingPrice = this.cartShipping;

    this.cartTotal = Number((this.cartSubtotal + this.cartShipping).toFixed(2));
    this.basketService.priceTotal = this.cartTotal;
    console.log("Total: " + this.basketService.priceTotal);
    this.checkProductQuantities();
  }

  itemRemoved(itemName : string){
    console.log("item " + itemName + " removed");
    switch (itemName) {
      case "immunoG500":
        this.immuno500 = 0;
        break;
      case "holesterolExComplex":
      this.holex = 0;
      break;
      case "acidEx":
      this.acidex = 0;
      break;
      case "q10":
      this.naturalQ10 = 0;
      break;
       
      default:
        break;
    }
    this.checkProductQuantities();
    this.basketService.calculateWeightAndNumbers();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // Extract changes to the input property by its name
    let change: SimpleChange = changes['data']; 
    console.log("Added to cart input changed");
    this.isShown = true;
    this.checkProductQuantities();
    this.addedToCart = !this.addedToCart;
    // Whenever the data in the parent changes, this method gets triggered
    // You can act on the changes here. You will have both the previous
    // value and the  current value here.
  }

  goToBasket(){
    this.router.navigateByUrl('/basket');
  }

  public isSerbianVersion() : boolean{
    return Utils.isSerbianVersion;
  }

  closePopupShop(){
    this.isShown = false;
  }
}
