import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

	public params :any = {};


  	constructor(private http: HttpClient) { }


  	addMessage(senderId : string, recieverId : string, message : string, senderUsername: string,
  	recieverUsername: string) : Observable<any>{

		let result: Observable<Object>;
		console.log("Sending message - chat service: " + senderId + " : " + recieverId + " : " + message);

		const uploadData = new FormData();

  		uploadData.append('senderId', senderId);
		uploadData.append('recieverId', recieverId);
		uploadData.append('message', message);
		uploadData.append('senderName', senderUsername);
		uploadData.append('recieverName', recieverUsername);



		return this.http.post("https://immuno-pharma-web-service.herokuapp.com/chats/addMessage", uploadData, {
	    	observe: 'response'
		});	
  	}

  	getAllWithId(userId : string): Observable<any> {
		let headers = new HttpHeaders();
      	headers.append('Content-Type', 'application/json');

      	this.params["userId"] = userId;

		return this.http.get('https://immuno-pharma-web-service.herokuapp.com/chats/getAllWithId', {headers:headers, params: this.params});
	}



}
