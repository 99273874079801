import { AuthService } from '../shared/auth/auth.service';
import { QuestionService } from '../shared/question/question.service';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {PopupComponent} from '../popup/popup.component'
import {LoadingPopupComponent} from '../loading-popup/loading-popup.component'
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import { BasketService } from '../shared/basket/basket.service';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public currentlyOpenDialog : MatDialogRef<PopupComponent, any>;
  public loadingDialog : MatDialogRef<LoadingPopupComponent, any>;
  public totalPriceVar : number;
  public firstNameVar = "";
  public lastNameVar = "";
  public emailVar = "";
  public addressVar = "";
  public houseNumVar = "";
  public aptNumVar = "";
  public cityVar = "";
  public countryVar = "";
  public zipcodeVar = "";
  public userData : any = {};

  immuno100 = 0;
  immuno500 = 0;
  holex = 0;
  acidex = 0;
  naturalQ10 = 0;

  constructor(private route: ActivatedRoute,
		private router: Router, private basketService: BasketService, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit() {
    this.totalPriceVar = this.basketService.totalPrice;
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if(this.userData != null){
      console.log("user data present: " + this.userData);
      this.firstNameVar = this.userData['firstName'];
      this.lastNameVar = this.userData['lastName'];
      this.emailVar = this.userData['email'];
      this.addressVar = this.userData['address'];
      this.houseNumVar = this.userData['entranceNum'];
      this.aptNumVar = this.userData['apartmentNum'];
      this.countryVar = this.userData['country'];
      this.cityVar = this.userData['city'];
      this.zipcodeVar = this.userData['postalNumber'];
    }
    this.checkProductQuantities();

  }

  checkProductQuantities(){

    if(localStorage.getItem("immuno100") != null){
      this.immuno100 = Number.parseInt(localStorage.getItem("immuno100"));
    }

    if(localStorage.getItem("immuno500") != null){
      this.immuno500 = Number.parseInt(localStorage.getItem("immuno500"));
    }
    if(localStorage.getItem("holex") != null){
      this.holex = Number.parseInt(localStorage.getItem("holex"));
    }
    if(localStorage.getItem("acidex") != null){
      this.acidex = Number.parseInt(localStorage.getItem("acidex"));
    }
    if(localStorage.getItem("q10") != null){
      this.naturalQ10 = Number.parseInt(localStorage.getItem("q10"));
    }

  }

  getStripeToken() {
    let form = document.getElementsByTagName("form")[0];
    (<any>window).Stripe.card.createToken({
      number: form.cardNumber.value,
      exp_month: form.expMonth.value,
      exp_year: form.expYear.value,
      cvc: form.cvc.value
    }, (status: number, response: any) => {
      if (status === 200) {
        let token = response.id;
        this.chargeCard(token);
      } else if(status == 402){
        //Wrong card number
        //this.openDialog(response.error.message);
        console.log(response.error.message);
      }else{
        this.openDialog(response.error.message);
      }
    });
  }

  chargeCard(token : string){
    this.basketService.userData = this.createUserObject();
    this.basketService.charge(token).subscribe(data => {
      if(data != null){
        console.log("Stripe data: " + data);
        if(this.userData != null && this.userData['id'] != null){
          this.authService.updateUser(this.basketService.userData).subscribe(data => {
            if(data != null){
              console.log(data);
            }
            else{
              //TODO: handle
              if(this.loadingDialog != null)
                this.loadingDialog.close();
              this.openDialog("Error");
            }
          }, error => {
            console.log("Error updating user: " + error.status);
            if(error.status == "403"){
              //this.openDialog("Error while processing the transaction. No money will be charged from your card.");
            }else{
              //this.openDialog("Error while processing the transaction. No money will be charged from your card.");
            }
          });
        }else{
          //TODO: obavestiti korisnika o uspesnosti
        }
      }
      else{
        //TODO: handle
        if(this.loadingDialog != null)
          this.loadingDialog.close();
        this.openDialog("Error");
      }
    }, error => {
			console.log("Error loggin in: " + error.status);
			if(error.status == "403"){
				//this.openDialog("Error while processing the transaction. No money will be charged from your card.");
			}else{
        //this.openDialog("Error while processing the transaction. No money will be charged from your card.");
      }
		});
  }


  public openDialog(message : string) : void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
    this.currentlyOpenDialog = dialogRef;

    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Dialog closed with output:", data);
        if(this.loadingDialog != null)
          this.loadingDialog.close();
      }
    );
  }

  public openLoadingPopup() : void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      id: 'message',
      title: 'Logging in. Please wait, this will not take long.'
    };

    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.loadingDialog = dialogRef;

    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog closed with output:", data)
    );
}

  public createUserObject() : any{
    if(this.userData != null && this.userData['id'] != null && this.userData['id'] != undefined){
      console.log('Creating user object: ' + this.addressVar);
      const obj = {
        "id" : this.userData['id'],
        "firstName" : this.firstNameVar,
        "lastName": this.lastNameVar,
        "email" : this.emailVar,
        "phoneNum" : "",
        "address" : this.addressVar,
        "entranceNum" : this.houseNumVar,
        "apartmentNum" : this.aptNumVar,
        "country" : this.countryVar,
        "city" : this.cityVar,
        "postalNumber" : this.zipcodeVar,
        "topicsFollowing" : [],
        "questionsAsked" : [],
        "articlesLiked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : [],
        "activated" : true,
        "basket": {},
        "cards": [] 
      }

      return obj;
    }else{
      //TODO: handle za error
      //Napravi novog usera, ako nema postojeceg
      console.log('No user data, creating new user: ' + this.addressVar);

      const obj = {
        "id" : "noid",
        "firstName" : this.firstNameVar,
        "lastName": this.lastNameVar,
        "email" : this.emailVar,
        "phoneNum" : "",
        "address" : this.addressVar,
        "entranceNum" : this.houseNumVar,
        "apartmentNum" : this.aptNumVar,
        "country" : this.countryVar,
        "city" : this.cityVar,
        "postalNumber" : this.zipcodeVar,
        "topicsFollowing" : [],
        "questionsAsked" : [],
        "articlesLiked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : [],
        "activated" : true,
        "basket": {},
        "cards": [] 
      }

      return obj;
    }

  }

}
