import { Component, OnInit, Inject, SecurityContext} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog} from '@angular/material';
import Utils from '../utils';
import { LoadingPopupComponent } from '../loading-popup/loading-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { BasketService } from '../shared/basket/basket.service';
import { count } from 'rxjs/operators';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-info-popup',
  templateUrl: './edit-info-popup.component.html',
  styleUrls: ['./edit-info-popup.component.scss']
})

export class EditInfoPopupComponent implements OnInit {

  private loadingDialog: MatDialogRef<LoadingPopupComponent, any>;

  public popupType : string;
  public isShippingPopup : boolean;

  public firstNameVar = "";
  public lastNameVar = "";
  public emailVar = "";
  public addressVar = "";
  public cityVar = "";
  public countryVar = "";
  public zipcodeVar = "";
  public cardNumberVar = "";
  public expMonthVar = "";
  public expYearVar = "";
  public cvcVar = "";

  public userData : any = {};

  private routerEventSubscription : Subscription;

  constructor(private router: Router, private basketService : BasketService, private dialogRef: MatDialogRef<EditInfoPopupComponent>, private dialog: MatDialog, 
    private sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) data) { 

    this.popupType = data['popupType'];
  }

  ngOnInit() {

    this.routerEventSubscription = this.router.events
      .subscribe(() => {
        this.dialogRef.close();
    });

    this.userData = null;

    if(localStorage.getItem("userData") != null){

      this.userData = JSON.parse(localStorage.getItem('userData'));
      this.firstNameVar = this.userData["firstName"];
      this.lastNameVar = this.userData["lastName"];
      this.emailVar = this.userData["email"];
      this.addressVar = this.userData["address"];
      this.cityVar = this.userData["city"];
      this.countryVar = this.userData["country"];
      this.zipcodeVar = this.userData["zipCode"];

    }else if(this.basketService.firstName != "" && this.basketService.firstName != null){

      this.firstNameVar = this.basketService.firstName;
      this.lastNameVar = this.basketService.lastName;
      this.emailVar = this.basketService.email;
      this.addressVar = this.basketService.address;
      this.cityVar = this.basketService.city;
      this.countryVar = this.basketService.country;
      this.zipcodeVar = this.basketService.zipCode;
    }

    if(this.popupType == "shipping"){
      this.isShippingPopup = true;
    }else{
      this.isShippingPopup = false;
    }
  }

  public isShippingInfoSanitized(firstName, lastName, city, country, address, zipcode, email) : boolean{

    console.log("Checking sanitization");

    this.firstNameVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, firstName));
    this.lastNameVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, lastName));
    this.emailVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, email));
    this.addressVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, address));
    this.countryVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, country));
    this.cityVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, city));
    this.zipcodeVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, zipcode));
    
    if(country == Utils.GetCountryTitleLocalized()){
      //TODO: alert
      this.openTimedDialog("Izberite državo, v katero bodo izdelki poslani.", 4000, null);
      return false;
    }

    if(firstName =="" || lastName == "" || email == "" || address == "" || city == "" || zipcode == ""){
      this.openTimedDialog("Nobeno prazno polje ni dovoljeno", 2000, null);
      return false;
    }

    if(!Utils.isAllLettersAndSpaces(firstName)){
      //TODO: alert
      this.openTimedDialog("Ime naj vsebuje samo črke in razmike.", 4000, null);
      return false;
    }
    if(!Utils.isAllLettersAndSpaces(lastName)){
      //TODO: alert
      this.openTimedDialog("Priimek naj vsebuje samo črke in razmike", 4000, null);
      return false;
    }
    if(!Utils.isEmail(email)){
      //TODO: alert
      this.openTimedDialog("E-mail mora vsebovati znake in številke, čemur sledi '@' in ime domene (.com, .org ...) ", 4000, null);
      return false;
    }
    if(!Utils.isAllLettersNumsSpacesSpecials(address)){
      //TODO: alert
      this.openTimedDialog("Naslov lahko vsebuje samo črke, številke, \\, /, ., - in ,", 4000, null);
      return false;
    }

    if(!Utils.isAllLettersAndSpaces(country)){
      //TODO: alert
      this.openTimedDialog("Ime države naj vsebuje samo črke in razmike", 4000, null);
      return false;
    }

    if(!Utils.isAllLettersNumsSpacesSpecials(city)){
      //TODO: alert
      this.openTimedDialog("Ime mesta naj vsebuje samo črke i razmike", 4000, null);
      return false;
    }

    if(!Utils.isAllLettersNumsSpacesSpecials(zipcode)){
      //TODO: alert
      this.openTimedDialog("Poštna številka lahko vsebuje samo črke, številke, \\, /, ., - in ,", 4000, null);
      return false;
    }

    return true;
  }

  public isPaymentInfoSanitized(cardNumber, expMonth, expYear, cvc) : boolean{

    console.log("Checking payment sanitization");

    this.cardNumberVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, cardNumber));
    this.expMonthVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, expMonth));
    this.expYearVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, expYear));
    this.cvcVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, cvc));
    

    if(cardNumber =="" || expMonth == "" || expYear == "" || cvc == ""){
      this.openTimedDialog("Nobeno prazno polje ni dovoljeno", 4000, null);
      return false;
    }

    if(!Utils.isAllNumbersSpecialsSpaces(this.cardNumberVar)){
      //TODO: alert
      this.openTimedDialog("Številka kartice naj vsebuje samo številke ", 6000, null);
      return false;
    }

    if(!Utils.isAllNumbersAndSpaces(this.expYearVar)){
      //TODO: alert
      this.openTimedDialog("Leto veljavnosti kartice mora vsebovati samo številke ", 6000, null);
      return false;
    }
    if(!Utils.isAllNumbersAndSpaces(this.expMonthVar)){
      //TODO: alert
      this.openTimedDialog("Mesec veljavnosti kartice mora vsebovati samo številke ", 6000, null);
      return false;
    }
    if(!Utils.isAllNumbersAndSpaces(this.cvcVar)){
      //TODO: alert
      this.openTimedDialog("CVC je 3 ali 4-mestna koda na zadnji strani kartice in mora vsebovati samo številke ", 6000, null);
      return false;
    }

    return true;
  }


  save(firstName, lastName, city, country, address, zipCode, email) {

    console.log("Country DOM element: " + country.options);
    var countryText = country.options[country.selectedIndex].text;
    console.log("Country text: " + countryText);

    if(this.isShippingInfoSanitized(firstName.value, lastName.value, city.value, countryText, 
      address.value, zipCode.value, email.value)){

      this.basketService.regionCode = country.value;
      this.basketService.country = this.countryVar;
      this.basketService.city = this.cityVar;
      this.basketService.firstName = this.firstNameVar;
      this.basketService.lastName = this.lastNameVar;
      this.basketService.address = this.addressVar;
      this.basketService.zipCode = this.zipcodeVar;
      this.basketService.email = this.emailVar;
      this.basketService.recalculatePrice();

      console.log("Log from edit popup: " + firstName + " " + this.firstNameVar + " " + this.basketService.firstName);

      this.dialogRef.close(true);

    }
  }

  savePaymentInfo(cardNumber, expMonth, expYear, cvc) {

    if(this.isPaymentInfoSanitized(cardNumber.value, expMonth.value, expYear.value, cvc.value)){
      
      this.basketService.cardNum = this.cardNumberVar;
      this.basketService.expMonth = this.expMonthVar;
      this.basketService.expYear = this.expYearVar;
      this.basketService.cardCVC = this.cvcVar;
      
      this.dialogRef.close(true);

    }
  }

  cancel() {
    this.dialogRef.close(false);
  }


  public openTimedDialog(message : string, time = 1000, afterCloseMessage : string) : MatDialogRef<LoadingPopupComponent, any> {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.loadingDialog = dialogRef;
    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog closed with output:", data)
    );

    setTimeout(()=>{
      this.loadingDialog.close();
      
    }, time);

    return dialogRef;
  }

  ngOnDestroy():void{
    if(this.routerEventSubscription != null){
      this.routerEventSubscription.unsubscribe();
    }
  }
}
