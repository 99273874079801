import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '../shared/chat/chat.service';


@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit {

	userData : any = {};
	userToMessage : any = {};
	chatData : any = [];
	selectedChat : any = {};

  constructor(private router : Router, private chatService : ChatService) { }

  ngOnInit() {
  	this.userData = JSON.parse(localStorage.getItem("userData"));

  	if(localStorage.getItem("userToMessage")!=null && localStorage.getItem("userToMessage")!=undefined){
  		this.userToMessage = JSON.parse(localStorage.getItem("userToMessage"));
  	}

  	this.chatService.getAllWithId(this.userData.id).subscribe(data => {
      console.log(data);
      this.chatData = data;
    });

  }

  selectChat(chat : any):void{
  	localStorage.setItem("selectedChat", JSON.stringify(chat));
  	this.selectedChat = chat;
  }

  sendMessage(message : string):void{

  	this.chatService.addMessage(this.userData.id, this.userToMessage.id, message, this.userData.username,

  	this.userToMessage.username).subscribe(data => {
      console.log(data);
      this.selectedChat = data.body;
      localStorage.setItem("selectedChat", JSON.stringify(this.selectedChat));
    });

  	console.log("message sent");
  }

}
