import { AuthService } from '../shared/auth/auth.service';
import { QuestionService } from '../shared/question/question.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {PopupComponent} from '../popup/popup.component'
import {LoadingPopupComponent} from '../loading-popup/loading-popup.component'
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  constructor(private route: ActivatedRoute,
		private router: Router, private authService: AuthService, private dialog: MatDialog,
		private questionService: QuestionService) { }

	public userData: any = {};
	public hasMessage : boolean = false;
	public message : string;
	public question : string;
	public firstNameVar : string;
	public lastNameVar: string;
	public emailVar : string;
	public password : string;
	public currentlyOpenDialog : MatDialogRef<PopupComponent, any>;
	public loadingDialog : MatDialogRef<LoadingPopupComponent, any>;


	ngOnInit() {
		if(localStorage.getItem('message') != null){
			this.hasMessage = true;
			this.message = localStorage.getItem('message');
		}

		if(localStorage.getItem('question') != null)
			this.question = localStorage.getItem('question');
		
		if(localStorage.getItem('userData') != null){
			console.log("register component: user data present");
			this.userData = JSON.parse(localStorage.getItem('userData'));
			this.firstNameVar = this.userData['firstName'];
			this.lastNameVar = this.userData['lastName'];
			this.emailVar = this.userData['email'];
		}

		console.log("User question: " + this.question);

	}

  	registerUser(firstName, lastName, email, password, repeatedPassword) {
		console.log("Register user");
		console.log(this.userData);
		
		if(password.value != repeatedPassword.value){
			console.log("Passwords do not match");
			this.openDialog("Your passwords do not match");
		}else{

			this.openLoadingPopup();

			if(this.userData['email']==null){
				this.userData['email'] = email.value;
				this.userData['firstName'] = firstName.value;
				this.userData['password'] = password.value;
				this.userData['lastName'] = lastName.value;
			}
			this.authService.registerUser(email.value, firstName.value, lastName.value, password.value)
			.subscribe(data => {
				console.log(data);
				if(data != null){
					localStorage.setItem('userData', JSON.stringify(data));

					if(this.question != null && this.question != ""){
						//TODO: izbaciti popup koji kaze da se postavlja pitanje i ceka registracija
						this.questionService.addQuestion(data['id'], data['email'], data['firstName'], data['lastName'], this.question)
						.subscribe(data => {
							console.log(data);
							console.log(JSON.stringify(data));
							//Brisemo pitanje iz lokala
							if(localStorage.getItem('question') != null)
								localStorage.removeItem('question');
								
							if(data != null && data['status'] == 200){
								console.log("Question successfuly asked.");
								//TODO: prikazati popup koji obavestava da je sve ok i na ok vraca na Home
								if(this.loadingDialog != null)
									this.loadingDialog.close();
								this.openDialogAndGoHome();
							}else{
								//TODO: Doslo je do greske
							}
						});
					}else{
						if(this.loadingDialog != null)
							this.loadingDialog.close();
						this.openDialogAndGoHome();
					}
				}
				else{
					//TODO: handle
					if(this.loadingDialog != null)
						this.loadingDialog.close();
					this.openDialog("An account with this email already exists");
				}
			},
			error  => {
			
				console.log("Error", error);
				this.loadingDialog.close();
				this.openDialog("Oooops, an error! Its either your internet, or our servers. Please, contact our support team at nameksoft@gmail.com, and they will help you and give you a gift!");
				return;
			});
		}
		
		
	}

	public openDialog(message : string) : void {

        const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;

        dialogConfig.data = {
	        id: 'message',
	        title: message
	    };

		const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
		this.currentlyOpenDialog = dialogRef;

        dialogRef.afterClosed().subscribe(
	        data => console.log("Dialog closed with output:", data)
		);
		
	}

	public openDialogAndGoHome(message? : string) : void{
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.disableClose = true;
		var titleMessage;

		if(this.question != null && this.question != ""){
			titleMessage = 'You are now registered, and your question has been successfuly sent.';
		}
		else{
			titleMessage = 'You are successfuly registered. Please activate your account by clicking the link we have sent to your email. Thank you!'
		}

        dialogConfig.data = {
	        id: 'message',
	        title: titleMessage
	    	};

        const dialogRef = this.dialog.open(PopupComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
			data => {
				console.log("Dialog closed with output:", data);
				this.router.navigate(['/home']);
			}
	    );
	}

	public openLoadingPopup() : void {

        const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.disableClose = true;

        dialogConfig.data = {
	        id: 'message',
	        title: 'Registration in progress. Please wait, this will not take long.'
	    	};

        const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
		this.loadingDialog = dialogRef;

        dialogRef.afterClosed().subscribe(
	        data => console.log("Dialog closed with output:", data)
	    );
	}

}
