import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss']
})
export class LoadingPopupComponent implements OnInit {

	message:string;
  	
  	constructor(
        private dialogRef: MatDialogRef<LoadingPopupComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        this.message = data['title'];
    }

  	ngOnInit() {
  	}

  	close() {
        this.dialogRef.close();
    }
}