import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from '../shared/basket/basket.service';
import Utils from '../utils';

@Component({
  selector: 'app-popup-shop-item',
  templateUrl: './popup-shop-item.component.html',
  styleUrls: ['./popup-shop-item.component.scss']
})
export class PopupShopItemComponent implements OnInit {

  @Output() itemRemovedEvent = new EventEmitter<string>();
  @Output() valueChangedEvent = new EventEmitter<number>();

  @Input()
  productName: string;
  @Input()
  productQuantityChanged;

  productPrice: number;
  productDescription: string;
  imageSrc: string;
  productQuantity : number;
  productTitle : string;
  totalPrice : number;
  localStorageKey : string;


  constructor(private basketService : BasketService, private translate : TranslateService) { }

  ngOnInit() {
    this.totalPrice = 0 as number;
    console.log("Total price initialised to: " + this.totalPrice);
    this.initialiseComponent();
  }

  initialiseComponent(){

    if(this.productName == "immunoG100"){
      if(localStorage.getItem("immuno100") != null){
        this.productQuantity = Number.parseInt(localStorage.getItem("immuno100"));
        this.productDescription = "Neravni imunomodulator";
        if(Utils.isSerbianVersion){
          this.productPrice = this.basketService.immunoGPriceSerbia;
        }
        else{
          this.productPrice = this.basketService.immunoGPrice;
        }        this.imageSrc = "../../assets/images/immunoG.jpg";
        this.productTitle = "Immuno G+ 100";
        this.localStorageKey = "immuno100";
      }
    }
    
    if(this.productName == "immunoG500"){
      if(localStorage.getItem("immuno500") != null){
        this.productQuantity = Number.parseInt(localStorage.getItem("immuno500"));
        this.productDescription = this.translate.instant("basket.immuno_g_desc");
        if(Utils.isSerbianVersion){
          this.productPrice = this.basketService.immunoGPriceSerbia;
        }else{
          this.productPrice = this.basketService.immunoGPrice;
        }        this.imageSrc = "../../assets/images/immunoG.jpg";
        this.productTitle = "Immuno G+ 500";
        this.localStorageKey = "immuno500";

      }
    }

    if(this.productName == "holesterolExComplex"){
      if(localStorage.getItem("holex") != null){
        this.productQuantity = Number.parseInt(localStorage.getItem("holex"));
        this.productDescription = this.translate.instant("basket.holex_desc");
        if(Utils.isSerbianVersion){
          this.productPrice = this.basketService.holexPriceSerbia;
        }else{
          this.productPrice = this.basketService.holexPrice;
        }        this.imageSrc = "../../assets/images/holex2.png";
        this.productTitle = "Holesterol - Ex Complex";
        this.localStorageKey = "holex";

      }
    }

    if(this.productName == "acidEx"){
      if(localStorage.getItem("acidex") != null){
        this.productQuantity = Number.parseInt(localStorage.getItem("acidex"));
        this.productDescription = this.translate.instant("basket.acidex_desc");
        if(Utils.isSerbianVersion){
          this.productPrice = this.basketService.acidexPriceSerbia;
        }else{
          this.productPrice = this.basketService.acidexPrice;
        }
        this.imageSrc = "../../assets/images/acidex2.png";
        this.productTitle = "Acid - Ex";
        this.localStorageKey = "acidex";

      }
    }

    if(this.productName == "q10"){
      if(localStorage.getItem("q10") != null){
        this.productQuantity = Number.parseInt(localStorage.getItem("q10"));
        this.productDescription = this.translate.instant("basket.q10_desc");
        if(Utils.isSerbianVersion){
          this.productPrice = this.basketService.q10PriceSerbia;
        }else{
          this.productPrice = this.basketService.q10Price;
        }        
        this.imageSrc = "../../assets/images/naturalq2.png";
        this.productTitle = "Natural Q10";
        this.localStorageKey = "q10";
      }
    }

    this.calculatePrice();
    console.log(this.productName + " initialized");
  }

  calculatePrice(){
    console.log("calculating price");
    if(this.totalPrice == undefined || this.totalPrice == null){
      this.totalPrice = 0;
    }
    var lastPrice : number = this.totalPrice;
    console.log("last price: " + lastPrice); 
    this.totalPrice =  Number((this.productPrice * this.productQuantity).toFixed(2));
    console.log(this.totalPrice + " , " + this.productQuantity);
    var total = Number((this.totalPrice - lastPrice).toFixed(2));
    console.log("Product total price: " + total);
    this.valueChangedEvent.emit(total);
    this.basketService.shippingPrice = this.basketService.calculateShipping();
    this.basketService.recalculatePrice();
  }

  public isSerbianVersion() : boolean{
    return Utils.isSerbianVersion;
  }

  onItemRemoved(){
    console.log("item removed");
    localStorage.removeItem(this.localStorageKey);
    localStorage.removeItem(this.localStorageKey+"total");
    this.itemRemovedEvent.emit('itemRemoved');
    this.valueChangedEvent.emit(Number((-this.productPrice * this.productQuantity).toFixed(2)));
    this.basketService.recalculatePrice();
  }

  onInputValueChanged(inputValue : number){
    console.log("Input value changed");
    this.productQuantity = inputValue;
    localStorage.setItem(this.localStorageKey, this.productQuantity.toString());
    this.calculatePrice();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // Extract changes to the input property by its name
    let change: SimpleChange = changes['productQuantityChanged']; 
    console.log("Added to cart input changed in basket-item: product quantity changed");
    this.initialiseComponent();
    // Whenever the data in the parent changes, this method gets triggered
    // You can act on the changes here. You will have both the previous
    // value and the  current value here.
  }

}
