import { Component, OnInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {


    message:string;
    dataLocal:string;
  	
  	constructor(
        private dialogRef: MatDialogRef<PopupComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

        console.log("Dialog data: " + data['title']);
        this.message = data['title'];
        this.dataLocal = data['title'];
    }

  	ngOnInit() {
      this.message = this.dataLocal;
  	}

  	close() {
        this.dialogRef.close();
    }
}
