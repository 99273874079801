export default class Utils {

   public static isSerbianVersion : boolean = true;
   public static isPaymentEnabled : boolean = true;

  public static isAllLettersAndSpaces(inputTxt) : boolean
  {
   var letters = /^[A-Za-z\s]+$/;
   console.log(inputTxt);
   if(inputTxt.match(letters))
      return true;
   else
      return false;
  }

  public static isAllNumbersAndSpaces(inputTxt) : boolean
  {
   var letters = /^[0-9\s]+$/;
   console.log(inputTxt);
   if(inputTxt.match(letters))
      return true;
   else
      return false;
  }

  public static escapeHtml(unsafe) {
    return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
                 .replace(/"/g, "&quot;").replace(/'/g, "&#039;");
  }

  public static isEmail(inputTxt) : boolean{
    var emailRegex = /[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\.[a-z]{2,3}/;
    if(inputTxt.match(emailRegex))
      return true;
    else
      return false;
  }

  public static isAllLettersNumsSpacesSpecials(inputTxt) : boolean
  {
   var letters = /^[0-9A-Za-z\s\+\-\\\/\.\,]+$/;
   console.log(inputTxt);
   if(inputTxt.match(letters))
      return true;
   else
      return false;
  }

  public static isAllNumbersSpecialsSpaces(inputTxt) : boolean{
    var letters = /^[0-9\s\+\-\\\/\.\,]+$/;
    console.log(inputTxt);
    if(inputTxt.match(letters))
        return true;
    else
        return false;
  }

  public static GetCountryTitleLocalized() : string{
     return "Država";
  }
  
}
