import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
	
	public params :any = {};


  	constructor(private http: HttpClient) { }

	updateUser(sellerData : any, selectedPhoto : File) : Observable<any>{

		let result: Observable<Object>;
		console.log("Username - service - " + sellerData.username);

		const uploadData = new FormData();

  		uploadData.append('email', sellerData.email);
		uploadData.append('currentPassword', sellerData.currentPassword);
		uploadData.append('newPassword', sellerData.newPassword);
		uploadData.append('fullName', sellerData.fullName);
		uploadData.append('username', sellerData.username);
		uploadData.append('description', sellerData.description);
		uploadData.append('photo', selectedPhoto);
		uploadData.append('id', sellerData.id);

		return this.http.post("https://immuno-pharma-web-service.herokuapp.com/sellers/update", uploadData, {
	    	reportProgress: true,
	    	observe: 'response'
		});	
  	}

  	follow(sellerId : string, whoToFollow : string) : Observable<any>{
  		console.log("Following: " + sellerId + " -> " + whoToFollow);

		const uploadData = new FormData();

  		uploadData.append('sellerId', sellerId);
		uploadData.append('whoToFollow', whoToFollow);
		

		return this.http.post("https://immuno-pharma-web-service.herokuapp.com/sellers/follow", uploadData, {
	    	reportProgress: true,
	    	observe: 'response'
		});
  	}

  	unfollow(sellerId : string, whoToFollow : string) : Observable<any>{
  		console.log("Following: " + sellerId + " -> " + whoToFollow);

		const uploadData = new FormData();

  		uploadData.append('sellerId', sellerId);
		uploadData.append('whoToFollow', whoToFollow);
		

		return this.http.post("https://immuno-pharma-web-service.herokuapp.com/sellers/unfollow", uploadData, {
	    	reportProgress: true,
	    	observe: 'response'
		});
  	}


}
