import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import {Router, ActivatedRoute} from '@angular/router';
import { ImmunoG100Component } from '../immuno-g100/immuno-g100.component';
import { BasketService } from '../shared/basket/basket.service';
import {EditInfoPopupComponent} from '../edit-info-popup/edit-info-popup.component'
import {PopupComponent} from '../popup/popup.component'
import {LoadingPopupComponent} from '../loading-popup/loading-popup.component'
import {SecurityContext} from '@angular/core'

import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import { AuthService } from '../shared/auth/auth.service';
import Utils from '../utils';
import { DomSanitizer } from '@angular/platform-browser';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})

export class BasketComponent implements OnInit {
  
  @ViewChild('info') private myScrollContainer: ElementRef;
  @ViewChild('paymentDiv') paymentDiv : ElementRef;
  @ViewChild('country') countrySelect : ElementRef;

  public currentDialog : MatDialogRef<EditInfoPopupComponent, any>;

  public currentlyOpenDialog : MatDialogRef<PopupComponent, any>;
  public loadingDialog : MatDialogRef<LoadingPopupComponent, any>;
  public totalPriceVar : number;

  public isWaitingPurchaseResponse = false;

  isImmunog100Active : boolean;
  immuno100 = 0;
  immuno500 = 0;
  holex = 0;
  acidex = 0;
  naturalQ10 = 0;

  immunoG500 = "immunoG500";
  acidEx = "acidEx";
  holesterolExComplex = "holesterolExComplex";
  q10 = "q10";
  immunoG100 = "immunoG100";

  cartSubtotal : number;
  cartShipping : number;
  cartTotal : number;

  userInfoExists : boolean;
  userDataString : string;
  userData : any = {};
  paymentInfoExists : boolean;

  public firstNameVar = "";
  public lastNameVar = "";
  public phoneNumVar = "";
  public emailVar = "";
  public addressVar = "";
  public cityVar = "";
  public countryVar = "";
  public zipcodeVar = "";
  public cardNumberVar = "";
  public expMonthVar = "";
  public expYearVar = "";
  public cvcVar = "";

  public receipt : any;

  public countryChosen : boolean;
  public paymentType : number = 0;

  constructor(private translate : TranslateService, private route: ActivatedRoute,
    private router: Router, private authService: AuthService, private http : HttpClient, public basketService : BasketService, 
    private dialog: MatDialog, private dialog2: MatDialog, private sanitizer: DomSanitizer) { }

  ngOnInit() {

    console.log("Payment div: " + this.paymentDiv);
    console.log("Country select: " + this.countrySelect);

    if(this.isSerbianVersion()){
      this.setSerbianVersionExceptions();
    }

    this.userData = null;
    this.checkProductQuantities();
    this.cartSubtotal = 0;
    this.cartTotal = 0;
    this.cartShipping = 0;

    if(localStorage.getItem("userData") != null){
      this.userData = JSON.parse(localStorage.getItem('userData'));
      console.log("user data exists");

      if(this.userData["address"] != null && this.userData["address"] != ""
      && this.userData["zipCode"] != null && this.userData["zipCode"] != ""
      && this.userData["country"] != null && this.userData["country"] != ""
      && this.userData["city"] != null && this.userData["city"] != ""){
        this.userInfoExists = true;
      }
    }else if(this.basketService.firstName != "" && this.basketService.firstName != null){
      console.log("user info exists");
      this.userInfoExists = true;
      this.firstNameVar = this.basketService.firstName;
      this.lastNameVar = this.basketService.lastName;
      this.phoneNumVar = this.basketService.phoneNum;
      this.emailVar = this.basketService.email;
      this.addressVar = this.basketService.address;
      this.countryVar = this.basketService.country;
      this.cityVar = this.basketService.city;
      this.zipcodeVar = this.basketService.zipCode;
    }

    if(this.userData != null){
      console.log("user data present: " + this.userData);
      this.firstNameVar = this.userData['firstName'];
      this.lastNameVar = this.userData['lastName'];
      this.phoneNumVar = this.userData['phoneNum'];
      this.emailVar = this.userData['email'];
      this.addressVar = this.userData['address'];
      this.countryVar = this.userData['country'];
      this.cityVar = this.userData['city'];
      this.zipcodeVar = this.userData['zipCode'];

      this.basketService.firstName = this.firstNameVar;
      this.basketService.lastName = this.lastNameVar;
      this.basketService.phoneNum = this.phoneNumVar;
      this.basketService.email = this.emailVar;
      this.basketService.address = this.addressVar;
      this.basketService.city = this.cityVar;
      this.basketService.country = this.countryVar;
      this.basketService.zipCode = this.zipcodeVar;
    }

  }


  itemRemoved(itemName : string){
    console.log("item " + itemName + " removed");
    switch (itemName) {
      case "immunoG500":
        this.immuno500 = 0;
        break;
      case "immunoG100":
      this.immuno100 = 0;
      break;
      case "holesterolExComplex":
      this.holex = 0;
      break;
      case "acidEx":
      this.acidex = 0;
      break;
      case "q10":
      this.naturalQ10 = 0;
      break;
       
      default:
        break;
    }
    this.checkProductQuantities();
    this.basketService.calculateWeightAndNumbers();
  }

  addToTotalPrice(event : any){
    console.log("addToSubtotal");
    console.log(typeof(event));
    console.log(event);
    var price = Number((event).toFixed(2));
    // this.cartSubtotal = Number((this.cartSubtotal + price).toFixed(2));
    this.cartSubtotal = this.basketService.calculateSubtotalPrice();
    this.basketService.priceSubtotal = this.cartSubtotal;

    this.cartShipping = Number(this.basketService.calculateShipping().toFixed(2));
    this.basketService.shippingPrice = this.cartShipping;

    this.cartTotal = Number((this.cartSubtotal + this.cartShipping).toFixed(2));
    this.basketService.priceTotal = this.cartTotal;
    console.log("Total: " + this.basketService.priceTotal);
    this.checkProductQuantities();
  }

  doNothing(){
    console.log("Nothing");
  }

  goToCheckout(firstName, lastName, phoneNum, city, country, address, zipCode, email, cardNumber, expMonth, expYear, cvc){
    
    console.log("go to checkout: " + firstName + ", " + cardNumber + ", "+ expMonth + ", "+ expYear + ", " + cvc);

    if(this.isSerbianVersion()){
      this.paymentType = 1;
    }

    if(!this.saveAndSanitize(firstName, lastName, phoneNum, city, country, address, zipCode, email,
       cardNumber, expMonth, expYear, cvc)){
        return;
    }

    console.log("Checkout: " + firstName.value);
    this.isWaitingPurchaseResponse = true;
    this.openLoadingPopup("Loading...");

    this.basketService.totalPrice = this.cartTotal;
    console.log("basket service price set: " + this.basketService.totalPrice);

    if(!this.userInfoExists){
      console.log("User info does not exist");
      this.isWaitingPurchaseResponse = false;
      this.openDialog(this.translate.instant("basket.fill_sender_info"));
      scrollTo(1000, 1000);
      this.closeLoadingPopup();
    }

    if(!this.paymentInfoExists && this.paymentType == 0){
      console.log("Payment info does not exist");
      this.isWaitingPurchaseResponse = false;
      this.openDialog(this.translate.instant("basket.fill_payment_info"));
      scrollTo(1000, 1000);
      this.closeLoadingPopup();
    }

    if(this.userInfoExists && this.paymentInfoExists && this.paymentType == 0){
      console.log("Check: " + this.basketService.cardNum)
      this.getStripeToken(this.basketService.cardNum, this.basketService.expMonth, this.basketService.expYear, this.basketService.cardCVC);
      return;
    }

    if(this.userInfoExists && this.paymentType == 1){
      console.log("Checkout: pay on delivery ");
      this.chargeCard("no_token");
    }
  }

  saveAndSanitize(firstName, lastName, phoneNum, city, country, address, zipCode, email, cardNumber, expMonth, expYear, cvc) : boolean{
    console.log("Country DOM element: " + country.options);
    var countryText = country.options[country.selectedIndex].text;
    console.log("Country text: " + countryText);

    if(this.paymentType == 1){
      if(this.isShippingInfoSanitized(firstName.value, lastName.value, phoneNum.value, city.value, countryText, 
        address.value, zipCode.value, email.value)){
          this.basketService.regionCode = country.value;
          this.basketService.country = this.countryVar;
          this.basketService.city = this.cityVar;
          this.basketService.firstName = this.firstNameVar;
          this.basketService.lastName = this.lastNameVar;
          this.basketService.phoneNum = this.phoneNumVar;
          this.basketService.address = this.addressVar;
          this.basketService.zipCode = this.zipcodeVar;
          this.basketService.email = this.emailVar;
          this.basketService.recalculatePrice();
          this.basketService.cardNum = 0;
          this.basketService.expMonth = 0;
          this.basketService.expYear = 0;
          this.basketService.cardCVC = 0;

          this.userInfoExists = true;

          console.log("Log from edit popup: " + firstName + " " + this.firstNameVar + " " + this.basketService.firstName);

          return true;
        }
    }

    if(this.isShippingInfoSanitized(firstName.value, lastName.value, phoneNum.value, city.value, countryText, 
      address.value, zipCode.value, email.value) && this.isPaymentInfoSanitized(cardNumber.value, expMonth.value, expYear.value, cvc.value)){

      this.basketService.regionCode = country.value;
      this.basketService.country = this.countryVar;
      this.basketService.city = this.cityVar;
      this.basketService.firstName = this.firstNameVar;
      this.basketService.lastName = this.lastNameVar;
      this.basketService.phoneNum = this.phoneNumVar;
      this.basketService.address = this.addressVar;
      this.basketService.zipCode = this.zipcodeVar;
      this.basketService.email = this.emailVar;
      this.basketService.recalculatePrice();
      this.basketService.cardNum = this.cardNumberVar;
      this.basketService.expMonth = this.expMonthVar;
      this.basketService.expYear = this.expYearVar;
      this.basketService.cardCVC = this.cvcVar;

      this.userInfoExists = true;
      this.paymentInfoExists = true;

      console.log("Log from edit popup: " + firstName + " " + this.firstNameVar + " " + this.basketService.firstName);

      return true;
      
    }else{
      return false;
    }
  }

  public isPaymentInfoSanitized(cardNumber, expMonth, expYear, cvc) : boolean{

    console.log("Checking payment sanitization: " + this.sanitizer + ", " + cardNumber);

    this.cardNumberVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, cardNumber));
    console.log("Checking payment sanitization: card number" + this.cardNumberVar + ", " + this.sanitizer + ", " + cardNumber);
    this.expMonthVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, expMonth));
    console.log("Checking payment sanitization: exp month" + this.expMonthVar + ", " + this.sanitizer + ", " + expMonth);
    this.expYearVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, expYear));
    console.log("Checking payment sanitization: exp year" + this.expYearVar + ", " + this.sanitizer + ", " + expYear);
    this.cvcVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, cvc));
    console.log("Checking payment sanitization: cvc" + this.cvcVar + ", " + this.sanitizer + ", " + cvc);

    if(cardNumber =="" || expMonth == "" || expYear == "" || cvc == ""){
      this.openTimedDialog(this.translate.instant("basket.e1"), 4000, null);
      return false;
    }

    if(!Utils.isAllNumbersSpecialsSpaces(this.cardNumberVar)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e2"), 6000, null);
      return false;
    }

    if(!Utils.isAllNumbersAndSpaces(this.expYearVar)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e3"), 6000, null);
      return false;
    }
    if(!Utils.isAllNumbersAndSpaces(this.expMonthVar)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e4"), 6000, null);
      return false;
    }
    if(!Utils.isAllNumbersAndSpaces(this.cvcVar)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e5"), 6000, null);
      return false;
    }

    return true;
  }

  public isShippingInfoSanitized(firstName, lastName, phoneNum, city, country, address, zipcode, email) : boolean{

    console.log("Checking sanitization");

    this.firstNameVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, firstName));
    this.lastNameVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, lastName));
    this.phoneNumVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, phoneNum));
    this.emailVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, email));
    this.addressVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, address));
    this.countryVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, country));
    this.cityVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, city));
    this.zipcodeVar = Utils.escapeHtml(this.sanitizer.sanitize(SecurityContext.HTML, zipcode));
    
    if(country == Utils.GetCountryTitleLocalized()){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e6"), 4000, null);
      return false;
    }

    if(firstName =="" || lastName == "" || phoneNum == "" || email == "" || address == "" || city == "" || zipcode == ""){
      this.openTimedDialog(this.translate.instant("basket.e1"), 2000, null);
      return false;
    }

    if(!Utils.isAllLettersAndSpaces(firstName)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e8"), 4000, null);
      return false;
    }
    if(!Utils.isAllLettersAndSpaces(lastName)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e9"), 4000, null);
      return false;
    }

    if(!Utils.isAllLettersNumsSpacesSpecials(phoneNum)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e13"), 4000, null);
      return false;
    }

    if(!Utils.isEmail(email)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e10"), 4000, null);
      return false;
    }
    if(!Utils.isAllLettersNumsSpacesSpecials(address)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e11"), 4000, null);
      return false;
    }

    if(!Utils.isAllLettersAndSpaces(country)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e12"), 4000, null);
      return false;
    }

    if(!Utils.isAllLettersNumsSpacesSpecials(city)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e13"), 4000, null);
      return false;
    }

    if(!Utils.isAllLettersNumsSpacesSpecials(zipcode)){
      //TODO: alert
      this.openTimedDialog(this.translate.instant("basket.e14"), 4000, null);
      return false;
    }

    return true;
  }


  addShippingInfo(){
    console.log("Open shipping dialog");
    this.openEditInfoDialog("shipping");
  }

  addPaymentInfo(){
    console.log("Open payment dialog");
    this.openEditInfoDialog("payment");
  }

  public openEditInfoDialog(editDialogType : string) : MatDialogRef<EditInfoPopupComponent, any> {

    console.log("Opening : " + editDialogType);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = 500;
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = 800;
    dialogConfig.width = "500px";

    dialogConfig.data = {
      popupType: editDialogType
    };

    const dialogRef = this.dialog.open(EditInfoPopupComponent, dialogConfig);

    this.currentDialog = dialogRef;
    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Edit " + editDialogType +  " info closed with output:", data)
        if(data == true){
          if(editDialogType == "shipping"){
            this.firstNameVar = this.basketService.firstName;
            this.lastNameVar = this.basketService.lastName;
            this.phoneNumVar = this.basketService.phoneNum;
            this.emailVar = this.basketService.email;
            this.addressVar = this.basketService.address;
            this.countryVar = this.basketService.country;
            this.cityVar = this.basketService.city;
            this.zipcodeVar = this.basketService.zipCode;
            this.userInfoExists = true;
          }else if(editDialogType == "payment"){
            this.paymentInfoExists = true;
          }
        }
      }
    );

    return dialogRef;
  }

  checkProductQuantities(){

    if(localStorage.getItem("immuno100") != null){
      this.immuno100 = Number.parseInt(localStorage.getItem("immuno100"));
    }else{
      this.immuno100 = 0;
    }
    this.basketService.products["immunoG100"] = this.immuno100;

    if(localStorage.getItem("immuno500") != null){
      this.immuno500 = Number.parseInt(localStorage.getItem("immuno500"));
    }else{
      this.immuno500 = 0;
    }
    this.basketService.products["immunoG500"] = this.immuno500;

    if(localStorage.getItem("holex") != null){
      this.holex = Number.parseInt(localStorage.getItem("holex"));
    }else{
      this.holex = 0;
    }
    this.basketService.products["holesterolExComplex"] = this.holex;

    if(localStorage.getItem("acidex") != null){
      this.acidex = Number.parseInt(localStorage.getItem("acidex"));
    }else{
      this.acidex = 0;
    }
    this.basketService.products["acidEx"] = this.acidex;

    if(localStorage.getItem("q10") != null){
      this.naturalQ10 = Number.parseInt(localStorage.getItem("q10"));
    }else{
      this.naturalQ10 = 0;
    }
    this.basketService.products["naturalQ10"] = this.naturalQ10;


  }

  getStripeToken(cardNumber, expMonth, expYear, cvcValue) {
    //TODO: zameniti form sa pravim poljima
    let form = document.getElementsByTagName("form")[0];
    (<any>window).Stripe.card.createToken({
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      cvc: cvcValue
    }, (status: number, response: any) => {

      this.isWaitingPurchaseResponse = false;

      if (status === 200) {
        let token = response.id;
        this.chargeCard(token);
        console.log("200");
      } else if(status == 402){
        //Wrong card number
        this.closeLoadingPopup();
        this.isWaitingPurchaseResponse = false;
        console.log("402");
        if(response.error.message != undefined && response.error.message != ""){
          this.openDialog(this.translate.instant("basket.e15") + response.error.message);
        }else{
          this.openDialog(this.translate.instant("basket.e17"));
        }
        return;
      }else{
        console.log("error - other");
        this.closeLoadingPopup();
        if(response.error.message != undefined && response.error.message != ""){
          this.openDialog(this.translate.instant("basket.e15") + response.error.message);
        }else{
          this.openDialog(this.translate.instant("basket.e17"));
        }
      }
    });
  }

  chargeCard(token : string){
    //error 402 je za los mesec
    
    this.basketService.userData = this.createUserObject();
    this.basketService.charge(token).subscribe(data => {

      this.isWaitingPurchaseResponse = false;

      this.closeLoadingPopup();

      if(data != null){
        console.log("Stripe data: " + data["order"]["totalPrice"]);
        this.receipt = data;
        this.openReceiptPopup();
      }
      else{
        console.log("Error while charging card");
        //TODO: handle
        this.openDialog(this.translate.instant("basket.e17"));
      }
    }, error => {
			console.log("Error: " + error.status);
			if(error.status == "403"){
        if(this.loadingDialog != null)
          this.loadingDialog.close();
        this.openDialog(this.translate.instant("basket.e17"));
			}else{
        if(this.loadingDialog != null)
          this.loadingDialog.close();
        this.openDialog(this.translate.instant("basket.e17"));
      }
		});
  }


  public openLoadingPopup(message = "Loading...") : void {

    console.log("Opening loading dialog");

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    if(message != ""){
      dialogConfig.data = {
        id: 'message',
        title: message
      };
    }else{
      dialogConfig.data = {
        id: 'message',
        title: this.translate.instant("basket.e19")
      };
    }
    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.loadingDialog = dialogRef;

    dialogRef.afterClosed().subscribe(
      data => console.log("Loading popup closed with output:", data)
    );
  }

  public openReceiptPopup() : void {

    if(this.loadingDialog != null)
      this.loadingDialog.close();

    console.log("Opening reciept dialog");

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    var message : string;
    message = "";

    if(this.receipt["order"]["itemsMap"]["immunoG100"] > 0){
      message += " Immuno G+ 100 : " + this.receipt["order"]["itemsMap"]["immunoG100"] + "\n";
    }
    if(this.receipt["order"]["itemsMap"]["immunoG500"] > 0){
      message += " Immuno G+ 500 : " + this.receipt["order"]["itemsMap"]["immunoG500"] + "\n";
    }
    if(this.receipt["order"]["itemsMap"]["holesterolExComplex"] > 0){
      message += " Holesterol-Ex Complex : " + this.receipt["order"]["itemsMap"]["holesterolExComplex"] + "\n";
    }
    if(this.receipt["order"]["itemsMap"]["acidEx"] > 0){
      message += " Acid-Ex : " + this.receipt["order"]["itemsMap"]["acidEx"] + "\n";
    }
    if(this.receipt["order"]["itemsMap"]["naturalQ10"] > 0){
      message += " Natural Q10 : " + this.receipt["order"]["itemsMap"]["naturalQ10"] + "\n";
    }


    dialogConfig.data = {
      title: this.translate.instant("basket.e20") + this.receipt["order"]["id"] + 
      this.translate.instant("basket.e21") + message
    };

    const dialogRef = this.dialog2.open(PopupComponent, dialogConfig);
    this.currentlyOpenDialog = dialogRef;
    this.currentlyOpenDialog.afterOpened().subscribe(result => {
      this.closeLoadingPopup();
    });

    dialogRef.afterClosed().subscribe(
      data => console.log("Reciept popup closed with output:", data)
    );
}

  public closeLoadingPopup(){
    if(this.loadingDialog != undefined)
      this.loadingDialog.close();
  }

  public createUserObject() : any{
    if(this.userData != null && this.userData['id'] != null && this.userData['id'] != undefined){
      console.log('Creating user object: ' + this.addressVar);
      const obj = {
        "id" : this.userData['id'],
        "firstName" : this.firstNameVar,
        "lastName": this.lastNameVar,
        "email" : this.emailVar,
        "phoneNum" : this.phoneNumVar,
        "address" : this.addressVar,
        "entranceNum" : "",
        "apartmentNum" : "",
        "country" : this.countryVar,
        "city" : this.cityVar,
        "zipCode" : this.zipcodeVar,
        "topicsFollowing" : [],
        "questionsAsked" : this.userData['questionsAsked'],
        "articlesLiked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : this.userData['orders'],
        "activated" : true,
        "basket": {},
        "cards": [] 
      }

      return obj;
    }else{
      //TODO: handle za error
      //Napravi novog usera, ako nema postojeceg
      console.log('No user data, creating new user: ' + this.addressVar);

      const obj = {
        "id" : "noid",
        "firstName" : this.firstNameVar,
        "lastName": this.lastNameVar,
        "email" : this.emailVar,
        "phoneNum" : this.phoneNumVar,
        "address" : this.addressVar,
        "entranceNum" : "",
        "apartmentNum" : "",
        "country" : this.countryVar,
        "city" : this.cityVar,
        "zipCode" : this.zipcodeVar,
        "topicsFollowing" : [],
        "questionsAsked" : [],
        "articlesLiked" : [],
        "profilePhotoUrl": "",
        "banned": false,
        "orders" : [],
        "activated" : true,
        "basket": {},
        "cards": [] 
      }

      return obj;
    }

  }


  public openDialog(message : string) : void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
    this.currentlyOpenDialog = dialogRef;

    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Dialog closed with output:", data);
        if(this.loadingDialog != null)
          this.loadingDialog.close();
      }
    );
  }

  public isSerbianVersion() : boolean{
    return Utils.isSerbianVersion;
  }

  public openTimedDialog(message : string, time = 1000, afterCloseMessage : string) : MatDialogRef<LoadingPopupComponent, any> {

    const dialogConfig = new MatDialogConfig();
    console.log("Opening timed dialog");
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.loadingDialog = dialogRef;
    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog closed with output:", data)
    );

    setTimeout(()=>{

      if(this.currentDialog != null){
        this.currentDialog.close();
      }

      if(afterCloseMessage != null){
        this.openTimedDialog(this.translate.instant("basket.e22"), 10000, null);
      }
    }, time);

    return dialogRef;
  }

  scrollToInfo(): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  public shippingChanged(code : number){
    this.basketService.shippingCode = code;
    this.basketService.shippingPrice = this.basketService.calculateShipping();
    console.log("Shipping code: " + this.basketService.shippingCode);
    this.addToTotalPrice(0);
  }

  public setCountry(countryElement){
    var countryText = countryElement.options[countryElement.selectedIndex].text;
    console.log("Country text: " + countryText);
    this.countryVar = countryText;
    this.paymentType = 0;
    this.basketService.country = countryText;
    if(countryText == "Serbia"){
      this.shippingChanged(2);
    }else{
      this.shippingChanged(0);
    }
  }

  public paymentChanged(paymentType : number, cardPaymentDiv : HTMLDivElement){
    this.paymentType = paymentType;
    console.log("Payment type: " + this.paymentType + ", " + cardPaymentDiv);
    if(paymentType == 0){
      cardPaymentDiv.style.display = "block";
    }else{
      cardPaymentDiv.style.display = "none";
    }
  }

  public setSerbianVersionExceptions(){
    this.paymentDiv.nativeElement.style.display = 'none';
  }

}
