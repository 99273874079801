import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ItemListComponent } from './item-list/item-list.component';
import { ItemAddComponent } from './item-add/item-add.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SellerProfileComponent } from './seller-profile/seller-profile.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { SellerEditComponent } from './seller-edit/seller-edit.component';
import { SingleItemComponent } from './single-item/single-item.component';
import { ChatWindowComponent } from './chat-window/chat-window.component';
import { ArticlesComponent } from './articles/articles.component';
import { ClanakHolesterol1Component } from './clanak-holesterol1/clanak-holesterol1.component';
import { FiveWaysToSaveYourHearthComponent } from './five-ways-to-save-your-hearth/five-ways-to-save-your-hearth.component';
import { ArticleGanodermaLucidumComponent } from './article-ganoderma-lucidum/article-ganoderma-lucidum.component';
import { BasketComponent } from './basket/basket.component';
import { NaturalQ10Component } from './natural-q10/natural-q10.component';
import { HolesterolExComplexComponent } from './holesterol-ex-complex/holesterol-ex-complex.component';
import { ImmunoG100Component } from './immuno-g100/immuno-g100.component';
import { ImmunoG500Component } from './immuno-g500/immuno-g500.component';
import { AcidExComponent } from './acid-ex/acid-ex.component';
import {CheckoutComponent} from './checkout/checkout.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'item-list',
    component: ItemListComponent
  },
  {
  	path: 'login',
  	component: LoginComponent
  },
  {
    path: 'item-add',
    component: ItemAddComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'seller-profile',
    component: SellerProfileComponent
  },
  {
    path: 'user-profile',
    component: UserProfileComponent
  },
  {
    path: 'seller-edit',
    component: SellerEditComponent
  },
  {
    path: 'single-item',
    component: SingleItemComponent
  },
  {
    path: 'chat-window',
    component: ChatWindowComponent
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: 'articleRegulateHolesterolNaturaly',
    component: ClanakHolesterol1Component
  },
  {
    path: 'fiveWaysToSaveYourHearth',
    component: FiveWaysToSaveYourHearthComponent
  },
  {
    path: 'articleGanodermaLucidum',
    component: ArticleGanodermaLucidumComponent
  },
  {
    path: 'basket',
    component: BasketComponent
  },
  {
    path: 'acidEx',
    component: AcidExComponent
  },
  {
    path: 'immunoG500',
    component: ImmunoG500Component
  },
  {
    path: 'immunoG100',
    component: ImmunoG100Component
  },
  {
    path: 'holesterolExComplex',
    component: HolesterolExComplexComponent
  },
  {
    path: 'naturalQ10',
    component: NaturalQ10Component
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',  // !!!
    scrollPositionRestoration: 'enabled',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
