import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemService } from '../shared/item/item.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-item-add',
  templateUrl: './item-add.component.html',
  styleUrls: ['./item-add.component.scss']
})

export class ItemAddComponent implements OnInit, OnDestroy {
  
	public API = 'https://immuno-pharma-web-service.herokuapp.com';
  	public ITEM_API = this.API + '/items';
  	public ADD_ITEM_API = this.ITEM_API + '/addItem'

  	public item: any = {};
  	selectedPhoto: File;


	constructor(private route: ActivatedRoute,
		private router: Router,
		private itemService: ItemService) {

	}

	ngOnInit() {
	}

	ngOnDestroy() {

	}

	gotoList() {
		this.router.navigate(['/item-list']);
	}

	onFileChanged(event) {
		this.selectedPhoto = event.target.files[0];
		console.log(this.selectedPhoto);
	}

	addItem(form: NgForm) {
		console.log("Adding item");
		console.log(this.item);
		this.itemService.addItem(this.selectedPhoto, this.item);
	}

}
