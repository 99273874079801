import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LoadingPopupComponent } from '../loading-popup/loading-popup.component';
import { BasketService } from '../shared/basket/basket.service';

@Component({
  selector: 'app-natural-q10',
  templateUrl: './natural-q10.component.html',
  styleUrls: ['./natural-q10.component.scss']
})
export class NaturalQ10Component implements OnInit {

  public currentDialog : MatDialogRef<LoadingPopupComponent, any>;

  constructor(private dialog: MatDialog, private translate : TranslateService, private basketService : BasketService) { }

  ngOnInit() {
  }

  public addToCart(product : string, inputValue : any){
    console.log("Input value: " + inputValue.value);
    console.log("Product name: " + product);
    var quantity = this.tryParseInt(localStorage.getItem(product), null);
    var inputInt = this.tryParseInt(inputValue.value, null);

    if(inputInt === null){
      inputInt = 1;
    }
    console.log("Quantity in storage: " + quantity);
    console.log("Input int: " + inputValue.value);

    if(quantity!=null){
      console.log("Quantity not null");
      quantity += inputInt;
      localStorage.setItem(product, quantity.toString());
    }
    else{
      console.log("Quantity is null");
      localStorage.setItem(product, inputInt.toString());
    }
    
    console.log(localStorage.getItem(product));
    this.basketService.calculateWeightAndNumbers();
    this.openTimedDialog(this.translate.instant("home.product_added"), 1000, null);
  }

  public decreaseAmmount(inputValue : any){
    console.log("Input value: " + inputValue.value);
    var inputInt = this.tryParseInt(inputValue.value, null);
    if(inputInt == 1){
      return;
    } 
    inputValue.value = inputInt - 1;
  }

  public increaseAmmount(inputValue : any){
    console.log("Input value: " + inputValue.value);
    var inputInt = this.tryParseInt(inputValue.value, null); 
    inputValue.value = inputInt + 1;
  }

  public tryParseInt(str,defaultValue) : number{
    var retValue = defaultValue;
    if(str !== null) {
        if(str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
  }


  public openTimedDialog(message : string, time = 1000, afterCloseMessage : string) : MatDialogRef<LoadingPopupComponent, any> {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 'message',
      title: message
    };

    const dialogRef = this.dialog.open(LoadingPopupComponent, dialogConfig);
    this.currentDialog = dialogRef;
    dialogRef.afterClosed().subscribe(
      data => console.log("Dialog closed with output:", data)
    );

    setTimeout(()=>{
      this.currentDialog.close();
      if(afterCloseMessage != null){
        this.openTimedDialog("There was an error processing your question. Please contact our support at nameksoft@gmail.com, thank you.", 10000, null);
      }
    }, time);

    return dialogRef;
  }

}
